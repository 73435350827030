import { createPopper } from '@popperjs/core';
import Choices from 'choices.js';

export default function initFormSelects() {
    const element = document.querySelectorAll('.js-cf-form .js-select-input');
    const hints = document.querySelectorAll('.js-cf-form .js-form-hint');

    if (element.length > 0) {
        element.forEach(function (node) {
            let choices = new Choices(node, {
                placeholder: true,
                searchEnabled: false,
                loadingText: '',
                itemSelectText: '',
                allowHTML: true,
                classNames: {
                    containerOuter: 'choices form-select',
                },
            });
        });
    }

    if (hints) {
        hints.forEach(item => {
            const button = item.querySelector('.form-input__hintButton');
            const tooltip = item.querySelector('.form-input__hintTooltip');

            const tooltipInstance = createPopper(button, tooltip, {
                placement: 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [10, 10],
                        },
                    },
                    {
                        name: 'arrow',
                        options: {},
                    },
                ],
            });

            button.addEventListener('click', toggleTooltip);

            function toggleTooltip() {
                tooltip.toggleAttribute('data-show');
                tooltipInstance.update();
            }
        });
    }
}
