import { multipleSelect } from 'multiple-select-vanilla';

export default function initMultiselects() {
    const selects = document.querySelectorAll(
        '.js-cf-form .js-multiselect-input'
    );
    if (!selects) return;

    selects.forEach(function (node) {
        multipleSelect(node, {
            selectAll: false,
            showClear: true,
        });
    });
}
