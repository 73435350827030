import initFormFiles from './form/_form-file';
import initMultiselects from './form/_form-multiselect';
import initFormSelects from './helpers/form-inputs';

var documentReady = function () {
    initFormSelects();
    initFormFiles();
    initMultiselects();
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
