class FormFile {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.input = this.root.querySelector('[type="file"]');
        this.filesEl = this.root.querySelector('.form-file__buttonFiles');
        this.textEl = this.root.querySelector('.form-file__buttonText');

        return true;
    }

    init() {
        this.setInput();
    }

    setInput() {
        this.input.addEventListener('change', async () => {
            const { files } = this.input;
            let filesContent = '';

            Array.from(files).forEach((file, index) => {
                filesContent += this.renderFileButton(file, index);
            });

            this.filesEl.innerHTML = filesContent;
            this.checkFilesButton(filesContent);
            this.setFileRemove();
        });
    }

    setFileRemove() {
        const removeButtons = this.root.querySelectorAll(
            '.form-file__buttonFile'
        );
        if (!removeButtons) return;

        this.onRemove = this.onFileRemove.bind(this);
        removeButtons.forEach((item, index) => {
            item.addEventListener('click', this.onRemove);
        });
    }

    renderFileButton(file, index) {
        const { name } = file;

        const button = `<div class="form-file__buttonFile" data-index="${index}">
            <div>${name}</div>
            <div class="form-file__buttonFileRemove">-</div>
        </div>`;

        return button;
    }

    removeFileFromFileList(index) {
        const dt = new DataTransfer();
        const { files } = this.input;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (index !== i) {
                dt.items.add(file);
            }
        }

        this.input.files = dt.files;

        let filesContent = '';
        Array.from(this.input.files).forEach((file, index) => {
            filesContent += this.renderFileButton(file, index);
        });

        this.filesEl.innerHTML = filesContent;
        this.checkFilesButton(filesContent);
        this.setFileRemove();
    }

    checkFilesButton(filesContent) {
        if (filesContent.length > 0) {
            this.textEl.classList.add('-hide');
        } else {
            this.textEl.classList.remove('-hide');
        }
    }

    onFileRemove(event) {
        const { currentTarget } = event;
        const index = parseInt(currentTarget.getAttribute('data-index'));

        this.removeFileFromFileList(index);
    }
}

export default function initFormFiles() {
    const sections = document.querySelectorAll('.js-form-file');
    if (!sections) return;

    sections.forEach(item => new FormFile(item));
}
